import React from 'react';
import { Card, Table, Button, Menu, Dropdown, Icon, Tag, Statistic, Row, Col, message } from 'antd';
import Title from '../../component/title';
import useTable from '../../component/table';
import { MyLink } from '../../component/link';
import { formatDate, filterDate } from '../../component/util'
import Fetch from '../../Fetch';

const Actions = props => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button.Group>
                <Button icon="download" onClick={props.exportClick}>Export</Button>
                <Button icon="reload" onClick={props.refresh} />
            </Button.Group>
        </div>
    );
}

const List = props => {
    const [state, setState] = React.useState({ summary: {} })
    const [{ filter, order }, tb] = useTable('/claim', { order: { key: 'created_at', order: 'descend' } })

    React.useEffect(() => {
        Fetch.get('/claim/summary').then(r => {
            setState({ ...state, summary: r });
        })
    }, [])

    const onRow = (record) => {
        return {
            onDoubleClick: () => {
                window.open(`/claim/${record.id}`, '_blank')
            }
        }
    }

    const actionMenus = (record) => (
        <Menu>
            <Menu.Item><MyLink to={`/claim/${record.id}`}><Icon type="file" style={{ marginRight: 8 }} />Detail</MyLink></Menu.Item>
            <Menu.Item><MyLink to={`/claim/${record.id}/edit`}><Icon type="edit" style={{ marginRight: 8 }} />Edit</MyLink></Menu.Item>
        </Menu>
    )

    const renderRow = (v, b) => {
        return <span>
            <Tag color={b ? 'green' : 'red'}>{b ? 'Lunas' : 'Belum'}</Tag>
            <span style={{ paddingLeft: 2 }}>{parseInt(v).toLocaleString('in-ID')}</span>
        </span>
    }

    return (
        <Card title={<Title title={"Daftar Tagihan"} actions={<Actions  {...props} refresh={() => tb.fetchData()} exportClick={() => {
            let err = filterDate(filter, 'claim.created_at')
            if (err !== '') {
                message.error(err)
                return;
            }
            const url = tb.genFilterUrl(`/claim/export?limit=-1&start=0&access_token=${localStorage.getItem('token')}`, filter, order)
            window.open(Fetch.getUrl(url))
        }} />} />}>
            <Row gutter={8}>
                <Col span={6}>
                    <Statistic title="Piutang Unit" value={state.summary.unit || 0} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="Piutang Subsidi 1" value={state.summary.subsidi1 || 0} decimalSeparator="," groupSeparator="." />
                </Col>
                <Col span={6}>
                    <Statistic title="Piutang Subsidi 2" value={state.summary.subsidi2 || 0} decimalSeparator="," groupSeparator="." />
                </Col>
            </Row><br />
            <Table {...tb.tableProps} onRow={onRow}>
                <Table.Column key="created_at" dataIndex="created_at" title="Tanggal" render={formatDate}
                    {...tb.columnProps({ key: 'claim.created_at', type: 'startend' })} {...tb.sortProps('created_at')} />
                <Table.Column key="region" dataIndex="region.name" title="Regional"
                    {...tb.columnProps({ key: 'claim.region_id', type: 'selecturl', url: '/region', dataIndex: 'name', valueIndex: 'id' })} />
                <Table.Column key="po_number" dataIndex="spk.po_number" title="NO PO"  {...tb.columnProps({ key: 'spk.po_number', hint: 'Cari no PO' })} />
                <Table.Column key="po_date" dataIndex="spk.po_date" title="Tanggal PO" render={formatDate} {...tb.sortProps('spk.po_date')} />
                <Table.Column key="machine_number" dataIndex="stock.machine_number" title="No Mesin" {...tb.columnProps({ key: 'stock.machine_number', hint: 'Cari no mesin' })}
                    render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                <Table.Column key="chasis_number" dataIndex="stock.chasis_number" title="No Rangka"  {...tb.columnProps({ key: 'stock.chasis_number', hint: 'Cari no rangka' })}
                    render={(t, r) => <MyLink to={`/stock/${r.stock_id}`}>{t}</MyLink>} />
                <Table.Column key="buyer_name" dataIndex="spk.buyer_name" title="Nama pembeli"  {...tb.columnProps({ key: 'spk.buyer_name', hint: 'Cari nama pembeli' })}
                    render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} {...tb.sortProps('spk.buyer_name')} />
                <Table.Column key="stnk_name" dataIndex="spk.stnk_name" title="Nama STNK"  {...tb.columnProps({ key: 'spk.stnk_name', hint: 'Cari nama stnk' })}
                    render={(t, r) => <MyLink to={`/spk/${r.spk.id}`}>{t}</MyLink>} {...tb.sortProps('spk.stnk_name')} />
                <Table.Column key="marketing" dataIndex="marketing.name" title="Nama marketing" render={(t, r) => r.marketing ? <MyLink to={`/user/${r.marketing.id}`}>{t}</MyLink> : '-'}
                    {...tb.columnProps({ key: 'claim.marketing_id', type: 'selecturl', hint: "Cari user", dataIndex: "name", valueIndex: "id", url: "/user?limit=500" })} />
                <Table.Column key="otr" dataIndex="spk.otr_price" title="Otr" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                <Table.Column key="tenor" dataIndex="spk.tenor" title="Tenor" align="right" render={r => parseInt(r).toLocaleString('in-ID')} />
                <Table.Column key="unit" dataIndex="unit" title="Unit" align="right" render={(t, r) => renderRow(t, r.unit_done)} />
                <Table.Column key="unit_date" dataIndex="unit_date" title="Tanggal Cair" render={formatDate} />
                <Table.Column key="subsidi_leasing1" dataIndex="subsidi_leasing1" align="right" title="Subsidi Leasing 1" render={(t, r) => renderRow(t, r.subsidi_leasing1_done)} />
                <Table.Column key="subsidi_leasing2" dataIndex="subsidi_leasing2" align="right" title="Subsidi Leasing 2" render={(t, r) => renderRow(t, r.subsidi_leasing2_done)} />
                <Table.Column key="motor" dataIndex="motor.name" title="Motor" />
                <Table.Column key="warna" dataIndex="color.name" title="Warna" />
                <Table.Column key="leasing" dataIndex="leasing.name" title="Leasing"
                    {...tb.columnProps({ key: 'claim.leasing_id', type: 'selecturl', hint: 'Pilih leasing', url: '/leasing?order=name asc', dataIndex: 'name', valueIndex: 'id' })}
                    {...tb.sortProps('leasing.name')} />
                <Table.Column key="note" dataIndex="note" title="Catatan" />
                <Table.Column key="receipt_number" dataIndex="claim_receipt.number" title="No tanda terima" />
                <Table.Column key="action" title="Aksi" align="center" width={50} render={(t, r) => {
                    return <Dropdown overlay={actionMenus(r)} trigger={['click']}><a href="void()">aksi</a></Dropdown>
                }} fixed="right" />
            </Table>
        </Card >
    )
}

export default List;